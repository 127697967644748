<!-- Controls the table and home screen for statement process -->
<template>
  <v-container class="pa-0 ma-0" fluid v-resize="onResize">
    <v-toolbar flat color="toolbar">
      <v-toolbar-title class="ml-2 title">
        Statement Manager
      </v-toolbar-title>
    <!-- API search for past statements based on key/value -->
    <v-divider vertical inset class="mx-4" />
    <v-row dense class="align-center d-flex">
      <!-- First Column: Search By Field -->
      <v-col cols="2" class="pa-0">
        <v-autocomplete
          dense
          solo
          flat
          outlined
          placeholder="Search By"
          :items="searchParams"
          item-text="description"
          return-object
          v-model="search.key"
          style="height: 40px; border-top-right-radius: 0; border-bottom-right-radius: 0;"
          :menu-props="{ maxHeight: 260, rounded: '' }"
        >
        </v-autocomplete>
      </v-col>

      <!-- Second Column: Search Value Field -->
      <v-col cols="3" class="pa-0">
        <template>
          <v-text-field
            dense
            solo
            flat
            outlined
            :placeholder="
                  search.key
                    ? `Search By ${search.key.description}`
                    : 'Select Search Field'
                "
            v-model="search.value"
            :disabled="!search.key"
            @keydown.enter="searchStatements"
            clearable
            @click:clear="clearSearch"
            style="height: 40px; border-radius: 0;"
          ></v-text-field>
        </template>
      </v-col>

      <!-- Third Column: Search Button -->
      <v-col cols="1" class="pa-0">
        <v-btn
          dense
          depressed
          :loading="searching"
          :disabled="!search.key || !search.value"
          style="height: 40px; border-top-left-radius: 0; border-bottom-left-radius: 0; border: 1px solid #9e9e9e; border-left:0px"
          color="primary"
          @click="searchStatements"
        >
          <v-icon>{{ 'mdi-magnify' }}</v-icon>
        </v-btn>
      </v-col>
        <v-col style="display: grid; justify-content: flex-end; grid-auto-flow: column; grid-column-gap: 12px">
           <v-btn
            color="success"
            @click="component='StatementModal', modal=true">
            New Statement
          </v-btn>
          <v-btn
            v-for="(process, key) in processButtons"
            :key="key"
            class="white--text"
            :color="getStatusColor(process.status)"
            @click="component=process.component, uploadMethod=process.name, modal=true, updateProcessOnClick(process, 'WAITING')"
            :loading="process_loading || process.status === 'RUNNING' || process.status === 'UPLOADING'">
              {{getProcessButtonText(process)}}
              <template v-slot:loader>
              <span :class="getLoader(process.status)">
                <v-icon dark>{{getLoaderIcon(process)}}</v-icon>
              </span>
              </template>
          </v-btn>
          <v-btn
            v-if="process_loading"
            class="white--text"
            color="#0c67a5"
            :loading="process_loading"
          />
        </v-col>
      </v-row>
    </v-toolbar>
      <v-divider></v-divider>
      <!-- Statement table, populated by API call -->
      <v-data-table
        dense
        :items-per-page.sync="pageSize"
        :headers="headers"
        :height="tableSize"
        :items="statements"
        :loading="loading"
        :expanded.sync="expanded"
        v-ripple="false"
        v-model="expanded"
        show-expand
        :sort-by="sortBy"
        :sort-desc.sync="sortDesc"
        single-expand
        item-key="id"
        :style="{'position': 'relative', 'z-index': '1'}"
        fixed-header
        hide-default-footer
        :search='filter'>
        <!--Statement table slot overrides -->
        <template #[`header.data-table-select`]="{ on, props }">
          <v-simple-checkbox :ripple="false" v-on="on" v-bind="props" dark />
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <v-chip v-if="item && item.status" small dark
            :color="getColor(item.status)">
            <span style="display: inline-block; margin: 0 auto; width: 70px; text-align: center">
              {{ item.status }}
            </span>
          </v-chip>
        </template>
        <template v-slot:[`item.created_on`]="{ item }">
          <span v-if="item && item.created_on">
            {{ formatDateTime(item.created_on) }}
          </span>
        </template>
        <template v-slot:[`item.updated_on`]="{ item }">
          <span v-if="item && item.updated_on">
            {{ formatDateTime(item.updated_on) }}
          </span>
        </template>
        <template #[`item.notes`]="{ item }">
          <v-btn
            icon
            @click="component='StatementNotes',selectedStatement=item, modal=true">
            <v-icon dense>mdi-comment-text</v-icon>
          </v-btn>
        </template>
        <template #[`item.info`]="{ item }">
        <v-tooltip top>
          <template #activator="{ on }">
            <v-btn v-on="on" icon @click.stop="copyID(item.statement_id)">
              <v-icon>mdi-information</v-icon>
            </v-btn>
          </template>
          <span>{{ item.statement_id }}</span>
        </v-tooltip>
      </template>
        <template #[`item.actions`]="{ item }">
          <v-menu open-on-click transition="slide-x-transition" bottom right offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-card class="mx-auto" max-width="300" tile>
              <v-list dense>
                <v-subheader>Actions</v-subheader>
                <v-list-item-group color="primary">
                  <v-list-item
                    :disabled="item.status.toLowerCase() !== 'incomplete'"
                    @click="selectedStatement=item, component='ConfirmCancel', modal=true">
                  <v-list-item-action>
                    <v-icon>
                      mdi-cancel
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-action>
                    <v-list-item-title>Cancel Statement</v-list-item-title>
                  </v-list-item-action>
                </v-list-item>
                  <v-list-item
                    :disabled="item.status.toLowerCase() !== 'incomplete'"
                    @click.stop="selectedStatement=item, component='ConfirmRerun', modal= true">
                  <v-list-item-action>
                    <v-icon>
                      mdi-run-fast
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-action>
                    <v-list-item-title>Re-run Statement</v-list-item-title>
                  </v-list-item-action>
                </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-menu>
        </template>
        <!-- Expanded table for posted batches included in a statement -->
        <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class='ma-0 pa-0'>
        <v-data-table
          dense
          :items-per-page="pageSize"
          :headers="expandedHeaders"
          :items="item.postedBatches"
          :loading="loading"
          hide-default-footer>
          <template #[`item.batch_info`]="{ item }">
            <v-tooltip top v-if="item">
              <template #activator="{ on }">
                <v-btn v-on="on" icon @click.stop="copyID(item.id)">
                  <v-icon>mdi-information</v-icon>
                </v-btn>
              </template>
          <span>{{ item.id }}</span>
        </v-tooltip>
        </template>
        </v-data-table>
        </td>
        </template>
      </v-data-table>
      <v-divider/>
      <!-- Pagination controls -->
      <v-toolbar flat color="#fafafa">
        <v-row align="center" justify="end">
          <v-col cols="2" style="max-width:150px;">
            <v-select class="mt-8" dense :items="pageSizes" label="Items Per Page" select v-model="pageSize"
              :menu-props="{ top: true, offsetY: true, maxHeight: 500 }">
            </v-select>
          </v-col>
          <v-btn icon large @click="previous()" :disabled="disablePrevious" class="mx-2">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <small>Page {{page}}</small>
          <v-btn icon large @click="next()" :disabled="disableNext" class="mx-2">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-row>
      </v-toolbar>
      <Component
        v-if="modal"
        :is="component"
        :value.sync="modal"
        :selectedStatement="selectedStatement"
        :selected="selected"
        :expanded="expanded"
        :method="uploadMethod"
        @closeModal="closeModal"
        @closeProcessModal="closeProcessModal">
      </Component>
  </v-container>
</template>
<script>
import { fullWidth } from '@/mixins/fullWidth'
import { displayAlert } from '@/mixins/displayAlert'
import { dateFormat } from '@/mixins/date-format'
import { userAccess } from '@/mixins/user-access'
import { utils } from '@/mixins/utils'
export default {
  data () {
    return {
      loading: true,
      searching: false,
      batches: [],
      processes: [],
      process_loading: true,
      process_timer: '',
      component: '',
      componentKey: 0,
      modal: false,
      filter:'',
      search: { value: null },
      searched: false,
      selected: [],
      expanded:[],
      selectedStatement:{},
      sortBy:'created_on',
      sortDesc:true,
      statements: [],
      headers: [
        { sortable: false, class: 'accent', value: 'data-table-select' },
        { text: 'Statement Name', class:'accent white--text',  value: 'name' },
        { text: 'Status', align: 'center', class:'accent white--text',  value: 'status' },
        { text: 'Created', class:'accent white--text',  value: 'created_on' },
        { text: 'Updated On', class:'accent white--text',  value: 'updated_on' },
        { text: 'Updated By', class:'accent white--text',  value: 'updated_by' },
        { text: 'Notes', class:'accent white--text',  value: 'notes' },
        { text: 'Statement ID', align: 'center', class:'accent white--text',  value: 'info', sortable: false },
        { sortable: false, class: 'accent', value: 'data-table-expand' },
        { text: 'Actions',align:'center', class:'accent white--text',  value: 'actions', sortable: false}
      ],
      expandedHeaders:[
        { sortable: false, width: '125'},
        { text: 'Batch Name', value: 'name' },
        { text: 'Batch Type',  value: 'batch_type' },
        { text: 'Start Date',  value: 'start_date' },
        { text: 'End Date', value: 'end_date' },
        { text: 'Batch ID', value: 'batch_info'}
      ],
      processAttributes: {
        'awg-billing-summaries': {
          buttonText: 'Billing Summaries',
          component: 'BillingSummaries'
        },
        'billing-sftp-upload': {
          buttonText: 'FTP Upload',
          component: 'FTPModal'
        },
        'statement-gen-doc-upload': {
          buttonText: 'Document Upload',
          component: 'StatementDocumentUpload'
        }
      },
      statusList:['PENDING', 'RUNNING', 'COMPLETE', 'POSTED', 'CANCELLED'],
      states: ['COMPLETE'],
      status:'',
      page: 1,
      statementPage: 1,
      pageSize: 50,
      pageSizes: [50, 100],
      uploadMethod: null
    }
  },
  name: 'Statement',
  mixins: [fullWidth, displayAlert, dateFormat, userAccess, utils],
  components: {
    StatementModal: () => import('@/components/StatementModal.vue'),
    StatementNotes: () => import('@/components/StatementNotes.vue'),
    ConfirmCancel: () => import('@/components/ConfirmCancel.vue'),
    ConfirmRerun: () => import('@/components/ConfirmRerun.vue'),
    BillingSummaries: () => import('@/components/BillingSummariesModal.vue'),
    FTPModal: () => import('@/components/FTPPushModal.vue'),
    StatementDocumentUpload: () => import('@/components/StatementDocumentUploadModal.vue')
  },
  watch: {
    modal: {
      handler (value) {
        if (!value) {
          this.selectedStatement = {}
        }
      }
    },
    pageSize: {
      handler () {
        if (this.page > 1) this.page = 1
        this.getStatements()
      }
    }
  },
  beforeCreate () {
    Promise.allSettled([
      this.$store.dispatch('getAdjustmentTypes'),
      this.$store.dispatch('getPartyTypes')
    ])
  },
  async created () {
    this.nonTableHeight = 225
    if (this.limitAccessByUserParties && this.userRelatedParties.length === 0) {
      await this.$store.dispatch('getUserRelatedParties')
    }
    await this.getStatements()
    this.process_timer = setInterval(this.getProcesses, 300000);
  },
  beforeDestroy() {
    clearInterval(this.process_timer);        
    this.process_timer = null;
  },
  computed: {
    processButtons() {
      let buttons = []
      if (this.processes.length > 0) {
        for (const [key, value] of Object.entries(this.processAttributes)) {
          const process = this.processes.find(p => p.name === key)
          if (process && (process?.status?.toLowerCase() !== 'inactive')) {
            buttons.push({
              ...process,
              buttonText: value.buttonText,
              component: value.component
            })
          }
        }
      }
      return buttons
    },
    buttonDisabled(){
      return (!this.search.key || !this.search.value)
    },
    responsibleParties () {
      return this.$store.getters.responsibleParties
    },
    offset () {
      return (this.page - 1) * this.pageSize
    },
    disablePrevious () {
      return this.offset === 0
    },
    disableNext () {
      return this.statements.length < this.pageSize
    },
    searchParams () {
      return [
        { description: 'Promotion Name', param: 'promo_name' },
        { description: 'Batch Name', param: 'batch_name' },
        { description: 'Statement Name', param: 'statement_name' },
        { description: 'Promo Number', param: 'promo_number' },
        { description: 'Promo Start Date', param: 'promo_start_date' }
      ]
    }
  },
  methods: {
    onResize () {
      this.tableHeight =  window.innerHeight - 225
    },
    getColor (status) {
      switch (status.toLowerCase()) {
        case 'pending':
          return 'black'
        case 'processing':
          return 'orange'
        case 'finalized':
          return 'green'
        case 'incomplete':
          return 'red'
        case 'cancelled':
          return 'grey lighten-1'
      }
    },
    getStatusColor (status) {
      if (!status) return '#0c67a5'
      switch (status.toLowerCase()) {
        case 'waiting':
          return '#0c67a5'
        case 'running':
          return 'purple'
        case 'uploading':
          return 'orange'
        case 'completed':
          return 'green'
        case 'failed':
          return 'red'
        case 'cancelled':
          return 'grey lighten-1'
      }
    },
    getLoader (status) {
      if (status === "RUNNING" || (!status)) {
        return 'loader-running'
      }
      else if (status === "UPLOADING"){
        return 'loader-uploading'
      }
    },
    getLoaderIcon (process) {
      if (!process.status) {
        return 'mdi-loading'
      }
      else {
        if (process.status === "RUNNING") {
          return "mdi-cached"
        }
        else if (process.status === "UPLOADING") {
          return "mdi-email-fast"
        }
      }
    },
    closeModal (update = false) {
      this.modal = false
      if (update) {
        this.getStatements()
      }
    },
    closeProcessModal () {
      this.modal = false
      this.getProcesses()
    },
    async getStatements () {
      if (this.searched) return this.searchStatements()
      this.loading = true
      const params = this.buildStatementListParams()
      let statements = []
      try {
        const res = await this.$Statements.getStatementList(params)
        if (res?.data?.length > 0) {
          statements = await this.getBatches(res.data)
        }
        this.statements = statements
      } catch (err) {
        this.handleError(err)
      } finally {
        this.loading = false
        this.getProcesses()
      }
    },
    async getProcesses() {
      const params = {}
      this.process_loading = true
      try {
        const res = await this.$Processes.getProcessList(params)
        if (res?.data?.length > 0) {
          this.processes = res.data
        }
      } catch (err) {
        this.handleError(err)
      } finally {
        this.process_loading = false
      }
    },
    updateProcessOnClick (process, status) {
      if (process.status.includes("COMPLETED") || process.status.includes("FAILED")) {
        this.updateProcessStatus(process, status)
      }
    },
    async updateProcessStatus (process, status) {
      this.process_loading = true
      try {
        const params = {"status": status}
        await this.$Processes.updateProcessStatus(process.process_id, params)
      } catch (err) {
        this.handleError(err)
      } finally {
        this.process_loading = false
      }
    },
    getProcessButtonText(process) {
      if (process.status === "WAITING" || process.status === "COMPLETED") {
        return process.buttonText
      } 
      else {
        return process.status
      }
    },
    buildStatementListParams () {
      const params = {
        limit: this.pageSize,
        offset: this.offset
      }
      if (this.limitAccessByUserParties) {
        params.party_ids = [...this.userBatchPartyIds]
      }
      return params
    },
    async getBatches (statements) {
      const promises = statements.map(statement => {
        return this.getStatementBatches(statement)
      })
      const results = await Promise.allSettled(promises)
      return statements.map((statement, index) => {
        let batches = []
        const result = results[index]
        if (result?.status === 'fulfilled') {
          batches = result.value.flatMap(r => r.data)
        }
        statement.postedBatches = batches
        return statement
      })
    },
    async getStatementBatches (statement) {
      const promises = statement.post_ids.map(post_id => {
        return this.$BillingBatch.getByPostId(post_id)
      })
      try {
        const { fulfilled, rejected } = await this.getAllSettled(promises, true)
        if (rejected.length > 0) throw rejected
        return fulfilled
      } catch (err) {
        this.handleError(err)
      }
    },
    async searchStatements () {
      this.loading = true
      this.searching = true
      let key = this.search.key?.param
      let value = this.search.value
      if (!this.searched) {
        this.statementPage = this.page
        this.page = 1
      }
      const params = this.buildStatementListParams()
      if (key && value) {
        params[`${key}`] = value
      }
      let statements = []
      try {
        const res = await this.$Statements.search(params)
        if (res?.data?.length > 0) {
          statements = await this.getBatches(res.data)
        }
        this.statements = statements
      } catch (err) {
        this.handleError(err)
      } finally {
        this.searching = false
        this.loading = false
        this.searched = true
      }
    },
    clearSearch() {
      this.page = this.statementPage
      this.search.value = null
      this.searched = false
      this.getStatements()
    },
    getResponsiblePartyName (id) {
      if (this.responsibleParties.length > 0) {
        const party = this.responsibleParties.find(p => p.id === id)
        if (party) {
          return party.name
        }
      }
      return ''
    },
    previous () {
      this.page = this.page - 1
      if (this.page < 1) {
        this.page = 1
      }
      if (this.searched) {
        this.searchStatements()
      } else {
        this.getStatements()
      }
    },
    next () {
      this.page = this.page + 1
      if (this.searched) {
        this.searchStatements()
      } else {
        this.getStatements()
      }
    },
    copyID (id) {
      navigator.clipboard.writeText(id)
      this.$store.dispatch('setSnackbar', { status: 'success', text: `Copied!` })
    },
  }
}
</script>

<style>
  .loader-running {
    animation: rotator 1s infinite;
    display: flex;
  }
  @-moz-keyframes rotator {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes rotator {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes rotator {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes rotator {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .loader-uploading {
    animation: slide 2.5s infinite;
    display: flex;
    transform: translate(-30px, 30px);
  }
    @-moz-keyframes slide {
    from {
      transform: translate(-30px);
    }
    to {
      transform: translate(30px);
    }
  }
  @-webkit-keyframes slide {
    from {
      transform: translate(-30px);
    }
    to {
      transform: translate(30px);
    }
  }
  @-o-keyframes slide {
    from {
      transform: translate(-30px);
    }
    to {
      transform: translate(30px);
    }
  }
  @keyframes slide {
    from {
      transform: translate(-30px);
    }
    to {
      transform: translate(30px);
    }
  }
</style>